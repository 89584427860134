// third-party
import { FormattedMessage } from 'react-intl';
import Groups2Icon from '@mui/icons-material/Groups2';

// assets
import WarehouseIcon from '@mui/icons-material/Warehouse';
import { IconChartArcs, IconListCheck, IconUsers, IconClipboardList, IconChartInfographic, IconDashboard, IconHome2, IconCalendarTime } from '@tabler/icons';

// constant
const icons = {
    IconChartArcs: IconChartArcs,
    IconListCheck: IconListCheck,
    IconUsers: IconUsers,
    IconClipboardList: IconClipboardList,
    IconChartInfographic: IconChartInfographic,
    IconDashboard: IconDashboard,
    IconHome2: IconHome2,
    IconCalendarTime: IconCalendarTime
};
//-----------------------|| WIDGET MENU ITEMS ||-----------------------//

export const ONDC = {
    id: 'ONDC',
    title: <FormattedMessage id="ONDC" />,
    type: 'group',
    children: [
        {
            id: 'products',
            title: <FormattedMessage id="products" />,
            type: 'item',
            url: '/ondc-products',
            icon: IconListCheck,
            breadcrumbs: false,
        },
        {
            id: 'My Profile',
            title: <FormattedMessage id="My Profile" />,
            type: 'item',
            url: '/ondc-seller-profile',
            icon: IconUsers,
            breadcrumbs: false,
        },
        {
            id: 'My Orders',
            title: <FormattedMessage id="My Orders" />,
            type: 'item',
            url: '/ondc-orders-list',
            icon: WarehouseIcon,
            breadcrumbs: false,
        },


    ]
}