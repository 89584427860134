import PropTypes from "prop-types";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { AppBar, CssBaseline, Toolbar, useMediaQuery } from "@material-ui/core";
import { useLocation } from "react-router";
// third-party
import clsx from "clsx";
import axios from "axios";

// project imports
import Breadcrumbs from "./../../ui-component/extended/Breadcrumbs";
import Header from "./Header";
import Sidebar from "./Sidebar";
// import Customization from './../Customization';
import navigation from "./../../menu-items";
import { drawerWidth } from "../../store/constant";
import { SET_MENU, LOGIN, LOGOUT } from "./../../store/actions";

// assets
import { IconChevronRight } from "@tabler/icons";
import { useEffect } from "react";

// style constant
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    backgroundColor: theme.palette.background.default,
  },
  appBarWidth: {
    transition: theme.transitions.create("width"),
    backgroundColor: theme.palette.background.default,
  },
  content: {
    ...theme.typography.mainContent,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    padding: "0px",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up("md")]: {
      marginLeft: -(drawerWidth - 20),
      width: `calc(100% - ${drawerWidth}px)`,
      padding: "0px",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "20px",
      width: `calc(100% - ${drawerWidth}px)`,
      padding: "0px",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10px",
      width: `calc(100% - ${drawerWidth}px)`,
      padding: "0px",
      marginRight: "10px",
    },
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    [theme.breakpoints.down("md")]: {
      marginLeft: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10px",
    },
  },
}));

//-----------------------|| MAIN LAYOUT ||-----------------------//

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const location = useLocation();
  let intervalRef = useRef(null);

  // Handle left drawer
  const leftDrawerOpened = useSelector((state) => state.customization.opened);
  const dispatch = useDispatch();
  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };

  React.useEffect(() => {
    const openLeftDrawerState = (val) => {
      dispatch({ type: SET_MENU, opened: val });
    };
    openLeftDrawerState(matchUpMd);
  }, [dispatch, matchUpMd]);
  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };
  const setSession = (serviceToken) => {
    if (serviceToken) {
      localStorage.setItem("serviceToken", serviceToken);
      axios.defaults.headers.common["Authorization"] = `Bearer ${serviceToken}`;
      axios.interceptors.response.use(
        (response) => response,
        (error) => {
          if (error.response.status === 403) {
            localStorage.removeItem("serviceToken");
            localStorage.setItem("url", location.pathname);
            delete axios.defaults.headers.common["Authorization"];
            window.location.reload();
          } else {
            return Promise.reject(error);
          }
        }
      );
    } else {
      localStorage.removeItem("serviceToken");
      delete axios.defaults.headers.common["Authorization"];
      // sessionStorage.setItem('url', location.pathname)
      window.location.reload();
      console.log("error h");
    }
  };

  const refreshToken = () => {
    if (localStorage.getItem("serviceToken")) {
      axios
        .get(process.env.REACT_APP_PATH + "/sellerCrm/admin/refreshtoken", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("serviceToken")}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            console.log("refresh Hua");
            console.log("res.data.data", res.data.data);
            setSession(res.data.data.refreshToken);
            localStorage.setItem("setRole", res.data.data.role);
            dispatch({ type: LOGIN });
          } else {
            dispatch({ type: LOGOUT });
            // dispatch(modeState('Manual'));
            // dispatch(dialerState('2'));
            clearInterval(intervalRef.current);
            // sessionStorage.setItem('url', location.pathname)
            window.location.reload();
            console.log("refresh nhi hua");
            // dispatch(storeId(''))
            // dispatch(dialerState('2'));
          }
        })
        .catch((err) => {
          console.log("err", err);
          localStorage.removeItem("serviceToken");
          delete axios.defaults.headers.common["Authorization"];
          // sessionStorage.setItem('url',location.pathname)
          window.location.reload();
          clearInterval(intervalRef.current);
          dispatch({
            type: LOGOUT,
          });
          // dispatch(storeId(''));
          // dispatch(dialerState('2'));
        });
    } else {
      localStorage.removeItem("serviceToken");
      // sessionStorage.setItem('url',location.pathname);
      // dispatch(storeId(''))
      delete axios.defaults.headers.common["Authorization"];
      window.location.reload();
      clearInterval(intervalRef.current);
      dispatch({
        type: LOGOUT,
      });
    }
  };
  React.useEffect(() => {
    let expTime = parseJwt(localStorage.getItem("serviceToken"));
    // let expTime = localStorage.getItem("expiredTime")
    // console.log('expTime', expTime)
    let tokenRefreshTime = expTime?.exp * 1000 - Date.now() - 1000 * 60 * 3;
    // console.log('tokenRefreshTime', tokenRefreshTime)

    intervalRef.current =
      localStorage.getItem("serviceToken") &&
      setInterval(() => {
        refreshToken()
      }, tokenRefreshTime);

    return () => {
      // console.log("clearTimeout(intervalRef.current)clearTimeout(intervalRef.current)")
    };
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      {/* header */}
      <AppBar
        position="fixed"
        color="inherit"
        elevation={0}
        className={leftDrawerOpened ? classes.appBarWidth : classes.appBar}
      >
        <Toolbar style={{ padding: "0px" }}>
          <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
        </Toolbar>
      </AppBar>

      {/* drawer */}
      <Sidebar
        drawerOpen={leftDrawerOpened}
        drawerToggle={handleLeftDrawerToggle}
      />

      {/* main content */}
      <main
        className={clsx([
          classes.content,
          {
            [classes.contentShift]: leftDrawerOpened,
          },
        ])}
      >
        {/* breadcrumb */}
        <Breadcrumbs
          separator={IconChevronRight}
          navigation={navigation}
          icon
          title
          rightAlign
        />
        <div>{children}</div>
      </main>
      {/* <Customization /> */}
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node,
};

export default MainLayout;
