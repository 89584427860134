import React, { useState, useEffect } from "react";

// material-ui
import { Typography } from "@material-ui/core";

// project imports
import NavGroup from "./NavGroup";
// import menuItem from './../../../../menu-items';
import { Appointments } from "../../../../menu-items/Appointments";
import { employee } from "../../../../menu-items/EmployeeHome";
import { customer } from "../../../../menu-items/CustomerHome";
import { services } from "../../../../menu-items/Services";
import { promotions } from "../../../../menu-items/Promotions";
import { employeeAccess } from "../../../../menu-items/EmployeeAccess";
import { dashboard } from "../../../../menu-items/dashboard";
import { useSelector } from "react-redux";
import { menuList_2 } from "./menuList_2";
import { reports } from "../../../../menu-items/Reports";
import { developer_area } from "../../../../menu-items/DevArea";
import { subscription } from "../../../../menu-items/Subscription";
import { ONDC } from "../../../../menu-items/ONDC";
//-----------------------|| SIDEBAR MENU LIST ||-----------------------//

const MenuList = () => {
  // let role = localStorage.getItem('setRole')
  const userDataRedux = useSelector((state) => state.userInfo.userData);
  const userRole = userDataRedux?.role || 3;

  let Role1 = [menuList_2, subscription, reports, developer_area];
  // let Role1 = [dashboard, Appointments, employee, customer, services,
  // promotions
  // ]
  // let Role2 = [dashboard, Appointments, employee, customer, services]
  // let Role2 = [menuList_2, reports]
  let Role2 = [menuList_2,subscription,ONDC];
  let Role3 = [employeeAccess];
  let Role4 = [employeeAccess];
  const [menuItem, setMenuItem] = useState({ items: [] });

  let sidebarItems = {
    1: Role1,
    2: Role2,
    3: Role3,
    4: Role4,
  };
  // // let menuItem =
  useEffect(() => {
    if (userRole != undefined && userRole != null) {
      setMenuItem({ items: sidebarItems[userRole] });
    }
  }, []);

  useEffect(() => {
    if (userRole != undefined && userRole != null) {
      setMenuItem({ items: sidebarItems[userRole] });
    }
  }, [userRole]);

  const navItems = menuItem.items.map((item) => {
    switch (item.type) {
      case "group":
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return navItems;
};

export default MenuList;
